import {AxiosService} from "./axios";
import {LogsRequestDto} from "../models/logs/LogRequestDto";
import {LogPageDto} from "../models/logs/LogPageDto";
import {BIN_URL} from "../shared/constUrl";
import {store} from "../store";

/**
 * Сервис для работы с корзиной
 */
export class BinService {
    /**
     * Сервис axios
     * @private
     */
    private axios: AxiosService;

    /**
     * Конструктор
     */
    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * Получить список удалённых логов.
     * @param options Параметры поиска
     */
    async getDeleteList(options?: LogsRequestDto): Promise<LogPageDto> {
        try {
            let response;
            if (options) {
                // Преобразуем числовые параметры в строки
                const params = new URLSearchParams({
                    pageNumber: (options.pageNumber) ? options.pageNumber.toString() : "1",
                    pageSize: options.pageSize.toString(),
                });

                response = await this.axios.get<LogPageDto>(BIN_URL + `?${params.toString()}`);
                store.binLogsStore.updateData(response.data);
            } else {
                response = await this.axios.get<LogPageDto>(BIN_URL);
            }
            return response.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Восстановить лог из корзины
     * @param logId Идентификатор лога
     */
    async restore(logId: number): Promise<void> {
        try {
            await this.axios.post(BIN_URL + '/restore/' + logId);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Удалить лог из корзины
     * @param logId Идентификатор лога
     */
    async delete(logId: number): Promise<void> {
        try {
            await this.axios.delete(BIN_URL + '/delete/' + logId);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Удалить все логи из корзины
     */
    async deleteAll(): Promise<void> {
        try {
            await this.axios.delete(BIN_URL + '/delete/all');
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Восстановить все
     */
    async recoverAll(): Promise<void> {
        try {
            await this.axios.post(BIN_URL + '/restore/all');
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}