import React, {useCallback, useEffect, useState} from "react";
import {ToolsContainer} from "../../containers/ToolsContainer";
import {Button, Input, Select} from "@fluentui/react-components";
import {LoadingButton} from "../../components/ButtonWithLoadingState";
import {LoadingState} from "../../models";
import {useServices, useStore} from "../../hooks";
import {COOKIE_LIST_URL} from "../../shared/constUrl";
import {useSearchParams} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {PaginationButtons} from "../../components/PaginationButtons";

/**
 * Инструменты для управления cookies
 * @constructor
 */
const CookiesTools: React.FC = () => {
    const services = useServices();
    const [searchParams] = useSearchParams();
    const store = useStore();
    const [loadingState, setLoadingState] = useState<LoadingState>("initial");
    const [keyword, setKeyword] = useState<string>();
    const [browser, setBrowser] = useState<string>('');
    const [options, setOptions] = useState<string[]>([]);

    /**
     * Получение опций для фильтрации
     */
    const getSearchOptions = useCallback(async () => {
        const options = await services.cookiesService.getOptions();
        setOptions(options);
        setBrowser(options[0]);
    }, [services.cookiesService]);

    /**
     * Обработчик ввода текста
     * @param event
     */
    const handleInputChange = (event: any) => {
        setKeyword(event.target.value); // Сохраняем введенное значение в состояние
    };

    /**
     * Загрузка списка Cookies и опций для фильтрации
     */
    useEffect(() => {
        getSearchOptions();
    }, [getSearchOptions]);

    /**
     * логика скачивания файла
     */
    const handleDownload = async () => {
        const logId = searchParams.get('botId') || '0';
        const params = new URLSearchParams({
            botId: logId,
            browserName: browser,
            keyword: keyword ?? ''
        });

        // Формируем полный URL с параметрами
        const urlWithParams = `${COOKIE_LIST_URL}/download?${params.toString()}`;
        await services.fileDownloadService.downloadFile(urlWithParams);
    };

    /**
     * Обработчик нажатия кнопки "Поиск"
     */
    const searchClickHandler = async () => {
        try {
            setLoadingState("loading");
            const response = await services.cookiesService.getList({
                pageSize: store.cookiesStore.params.pageSize,
                pageNumber: 0,
                botId: Number(searchParams.get('botId')) || 0,
                browserName: browser,
                keyword: keyword || ''
            });
            store.cookiesStore.updateData(response);
            setLoadingState("loaded");
        } catch (error: any) {
            setLoadingState("error");
        }
    };

    /**
     * Обработчик выбора элемента
     */
    const handleSelectChange = (event: any) => {
        const value = event.target.value;
        setBrowser(value); // Сохраняем выбранное значение в состояние
    };

    const onNextClick = async () => {
        try {
            const response = await services.cookiesService.getList({
                pageSize: store.cookiesStore.paging.pageSize,
                pageNumber: (store.cookiesStore.paging.pageNumber + 1),
                botId: Number(searchParams.get('botId')) || 0,
                browserName: store.cookiesStore.params.browserName,
                keyword: store.cookiesStore.params.keyword,
            });
            store.cookiesStore.updateData(response);
            setLoadingState("loaded");
        } catch (error: any) {
            setLoadingState("error");
        }
    }

    const onPrevClick = async () => {
        try {
            const response = await services.cookiesService.getList({
                pageSize: store.cookiesStore.paging.pageSize,
                pageNumber: (store.cookiesStore.paging.pageNumber - 1),
                botId: Number(searchParams.get('botId')) || 0,
                browserName: store.cookiesStore.params.browserName,
                keyword: store.cookiesStore.params.keyword,
            });
            store.cookiesStore.updateData(response);
            setLoadingState("loaded");
        } catch (error: any) {
            setLoadingState("error");
        }
    }

    return (
        <ToolsContainer>
            {/* Контейнер для всей строки */}
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}>
                {/* Левый блок (фильтры и кнопки) */}
                <div style={{display: "flex", alignItems: "center", gap: "1em"}}>
                    {/* Обёртка для браузера с лейблом */}
                    <div style={{display: "flex", alignItems: "center", gap: "0.5em"}}>
                        <label>Browser name:</label>
                        <Select onChange={handleSelectChange}>
                            {options.map((val) => (
                                <option key={val} value={val}>{val}</option>
                            ))}
                        </Select>
                    </div>

                    {/* Обёртка для ключевого слова с лейблом */}
                    <div style={{display: "flex", alignItems: "center", gap: "0.5em"}}>
                        <label>Keyword:</label>
                        <Input onChange={handleInputChange}></Input>
                    </div>

                    <LoadingButton
                        appearance={"primary"}
                        buttonText={"Search"}
                        state={loadingState}
                        onClick={searchClickHandler}
                    />
                    <Button onClick={() => handleDownload()}>
                        Download
                    </Button>
                </div>

                {/* Правый блок (выбор размера страницы и пагинация) */}
                <div style={{display: "flex", alignItems: "center", gap: "1em", marginLeft: "0.5em"}}>
                    {/*<Label style={{marginLeft: "0.5em"}}>Items per page:</Label>*/}
                    {/*<Select value={store.cookiesStore.paging.pageSize.toString()}>*/}
                    {/*    <option value={10}>10</option>*/}
                    {/*    <option value={25}>25</option>*/}
                    {/*    <option value={50}>50</option>*/}
                    {/*    <option value={100}>100</option>*/}
                    {/*    <option value={200}>200</option>*/}
                    {/*    <option value={300}>300</option>*/}
                    {/*</Select>*/}
                    <PaginationButtons
                        currentPage={store.cookiesStore.paging.pageNumber}
                        totalCount={store.cookiesStore.paging.totalItems}
                        totalPages={store.cookiesStore.paging.totalPages}
                        onNextClick={onNextClick}
                        onPrevClick={onPrevClick}
                    />
                </div>
            </div>
        </ToolsContainer>
    );
}

CookiesTools.displayName = "CookiesTools";

export default observer(CookiesTools);
