import React from "react";
import {Layout} from "../../containers/Layout";
import BinItemsList from "../../components/BinItemsList/BinItemsList";
import {observer} from "mobx-react-lite";
import BinTools from "./BinTools";

/**
 * Страница "Корзина"
 * @constructor
 */
const BinPage: React.FC = () => {
    return (
        <Layout pageTittle={"Bin"} anotherBackground={true}>
            <BinTools/>
            <BinItemsList/>
        </Layout>
    );
}

/**
 * Имя отображаемое при отладке
 */
BinPage.displayName = "BinPage";

export default observer(BinPage);
