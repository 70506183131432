import {AxiosService} from "./axios";
import {LogsRequestDto} from "../models/logs/LogRequestDto";

/**
 * Сервис для создания запросов на генерацию файлов для скачивания
 */
export class FileDownloadService {
    /**
     * Экземпляр axios сервиса
     * @private
     */
    private axios: AxiosService;

    /**
     *   Конструктор
     */
    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * Запросить все файлы для одного лога
     */
    async requestLogFiles(logId: number): Promise<void> {
        try {
            await this.axios.get(`/api/file/bot?id=${logId}`);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Запросить все файлы для нескольких логов
     */
    async requestLogsFiles(logId: number[]): Promise<void> {
        try {
            await this.axios.post('api/file/multiple', logId);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Запросить все файлы для всех логов пользователя
     */
    async requestAllLogFiles(): Promise<void> {
        try {
            await this.axios.get('api/file/all');
        } catch (error: any) {
            return Promise.reject(error);
        }
    }


    /**
     * Запросить файлы логов согласно фильтру
     * @param filter
     */
    async requestLogByFilter(filter: LogsRequestDto): Promise<void> {
        try {
            const p = this.buildQueryString(filter);
            await this.axios.get(`/api/file/all?${p}`);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Скачивание файла
     * @param targetUrl адрес файла
     */
    async downloadFile(targetUrl: string): Promise<void> {
        try {
            await this.axios.downloadFile(targetUrl);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    buildQueryString(params: LogsRequestDto): string {
        const queryParams = new URLSearchParams();

        Object.keys(params).forEach((key) => {
            let value = (params as any)[key]; // Используем типизацию как any для обращения к значениям
            if (value !== undefined && value !== null) {
                // Проверка на дату
                if (value instanceof Date) {
                    // Приведение даты к ISO формату
                    value = value.toISOString();
                }
                queryParams.append(key, value.toString());
            }
        });

        return queryParams.toString();
    }
}