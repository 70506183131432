import React, {useCallback, useEffect, useState} from "react";
import {
    Button,
    Input,
    Label,
    Toast,
    ToastBody,
    ToastTitle,
    useId,
    useToastController
} from "@fluentui/react-components";
import UseStyle from './TelegramNotificationsFormStyles';
import {useFormik} from "formik";
import {useServices} from "../../hooks";
import {defaultToastId} from "../../shared/constString";
import {TelegramNotificationsDto} from "../../models/telegramNotifications";
import {GetMessageByCode} from "../../utils";

/**
 * форма добавления | отображения бота
 * @constructor
 */
const TelegramNotificationsForm: React.FC = () => {
    const styles = UseStyle();
    const services = useServices();
    const toasterId = useId(defaultToastId);
    const {dispatchToast} = useToastController(toasterId);
    const [status, setStatus] = useState('');
    const [botName, setBotName] = useState('');

    const formik = useFormik({
        initialValues: {
            botKey: '',
            botName: '',
            status: '',
        },
        onSubmit: async (values) => {
            await onStart(values);
        },
    });

    /**
     * Получение статуса
     */
    const fetchStatus = useCallback(async () => {
        const resApiStatus = await services.telegramBotService.getTelegramBotStatus();
        const apiStatus = resApiStatus.data.status;
        const apiBotName = resApiStatus.data.botName;
        setStatus(apiStatus);
        setBotName(apiBotName || '');
        await formik.setFieldValue('botName', apiBotName || '');
    }, [services.telegramBotService, formik]);

    useEffect(() => {
        fetchStatus();
        const intervalId = setInterval(fetchStatus, 2000);
        return () => clearInterval(intervalId);
    }, [fetchStatus]);

    /**
     * Отображение ошибки
     * @param error
     */
    const notify = (error: any) => {
        dispatchToast(
            <Toast style={{minHeight: 50 + "px", minWidth: 200 + "px"}}>
                <ToastTitle>Error!</ToastTitle>
                <ToastBody>{GetMessageByCode(error.code)}</ToastBody>
            </Toast>,
            {intent: "error"}
        );
    }

    /**
     * запуск бота
     */
    const onStart = async (values: TelegramNotificationsDto) => {
        try {
            await services.telegramBotService.startBot(values.botKey);
        } catch (error: any) {
            notify(error);
        }
    };

    /**
     * остановка бота
     */
    const onStop = async () => {
        try {
            await services.telegramBotService.stopBot();
            setStatus('Stopped');
        } catch (error: any) {
            notify(error);
        }
    };

    return (
        <div className={styles.TelegramNotificationsForm}>
            <div className={styles.cardContainer}>
                <div className={styles.header}>
                    <h2>Telegram bot</h2>
                    <div className={styles.status}>
                        Status: {status}
                    </div>
                </div>
                <form onSubmit={formik.handleSubmit} className={styles.form}>
                    <div>
                        <Label htmlFor="botName">Bot name:</Label>
                        <Input
                            id="botName"
                            name="botName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.botName}
                            disabled
                        />
                        {formik.touched.botName && formik.errors.botName ? (
                            <div className={styles.errorMessage}>{formik.errors.botName}</div>
                        ) : null}
                    </div>
                    {(status === 'Unknown' || status === '') && (
                        <div>
                            <Label htmlFor="botKey">Bot key:</Label>
                            <Input
                                id="botKey"
                                name="botKey"
                                placeholder="Enter Bot API token"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.botKey}

                            />
                            {formik.touched.botKey && formik.errors.botKey ? (
                                <div className={styles.errorMessage}>{formik.errors.botKey}</div>
                            ) : null}
                        </div>
                    )}
                    {status !== 'Running' ? (
                        <Button size={"large"} appearance={"primary"} type={"submit"}>
                            Start
                        </Button>
                    ) : (
                        <Button size={"large"} appearance={"primary"} onClick={onStop}>
                            Stop
                        </Button>
                    )}
                </form>
            </div>
        </div>
    );
};
/**
 * Имя отображаемое при отладке
 */
TelegramNotificationsForm.displayName = "TelegramNotificationsForm";

export default TelegramNotificationsForm;