import {Layout} from "../../containers/Layout";
import CookieList from "../../components/CookiesList";
import React from "react";
import CookiesTools from "./CookiesTools";

/**
 * Страница для отображения cookies
 * @constructor
 */
const CookiesPage: React.FC = () => {
    return (
        <Layout pageTittle="Cookies" anotherBackground={true}>
            <CookiesTools/>
            <CookieList/>
        </Layout>
    );
};

CookiesPage.displayName = "CookiesPage";

export default CookiesPage;