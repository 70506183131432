import {ToolsContainer} from "../../containers/ToolsContainer";
import React, {useCallback, useState} from "react";
import {LoadingButton} from "../../components/ButtonWithLoadingState";
import {
    ArrowDownload20Regular,
    ArrowRight20Regular,
    BinRecycle20Filled,
    BinRecycle20Regular,
    DataFunnel20Filled,
    DataFunnel20Regular
} from "@fluentui/react-icons";
import {
    Button,
    Input,
    Link,
    Select,
    SelectProps,
    Text,
    Toast,
    ToastBody,
    ToastFooter,
    ToastTitle,
    tokens,
    useId,
    useToastController
} from "@fluentui/react-components";
import PaginationButtons from "../../components/PaginationButtons/PaginationButtons";
import {useServices, useStore} from "../../hooks";
import {SelectedCount} from "../../components/SelectedCount";
import {observer} from "mobx-react-lite";
import {ApiError} from "../../models";
import {GetMessageByCode} from "../../utils";
import {defaultToastId} from "../../shared/constString";
import {DOWNLOADS_URL} from "../../shared/constUrl";
import UseStyle from "./LogListToolsStyles";
import {useToast} from "../../utils/ToastProvider";

const LogListTools: React.FC = () => {
    const store = useStore();
    const {showToast} = useToast();
    const [nextPage, setNextPage] = useState<number>(0);
    const selectId = useId();
    const toasterId = useId(defaultToastId);
    const {dispatchToast} = useToastController(toasterId);
    const services = useServices();
    const styles = UseStyle();

    /**
     * Показ сообщения об ошибке
     * @param error Модель с ошибкой
     */
    const showErrorNotify = useCallback((error: ApiError) => {
        dispatchToast(
            <Toast style={{minHeight: "50px", minWidth: "100px"}}>
                <ToastTitle>Error!</ToastTitle>
                <ToastBody>{GetMessageByCode(error.code)}</ToastBody>
            </Toast>,
            {intent: "error"}
        );
    }, [dispatchToast]);

    /**
     * Обработчик кнопки "Следующая страница"
     */
    const onNextClickHandler = async () => {
        if (store.logsStore.params) {
            const value = (store.logsStore.params.pageNumber) ? store.logsStore.params.pageNumber + 1 : 1;
            store.logsStore.params = {...store.logsStore.params, pageNumber: value};
        }
    }

    /**
     * Обработчик кнопки "Предыдущая страница"
     */
    const onPrevClickHandler = async () => {
        if (store.logsStore.params) {
            const value = (store.logsStore.params.pageNumber && store.logsStore.params.pageNumber > 1) ? store.logsStore.params.pageNumber - 1 : 1;
            store.logsStore.params = {...store.logsStore.params, pageNumber: value};
        }
    }

    /**
     * Обработчик изменения размера таблицы
     * @param _event событие
     * @param data данные
     */
    const onSizeChangeHandler: SelectProps["onChange"] = (_event, data) => {
        const numberValue = parseInt(data.value);
        if (numberValue !== store.logsStore.params.pageSize) {
            store.logsStore.paging = {...store.logsStore.paging, pageNumber: 1};
            store.logsStore.params = {...store.logsStore.params, pageSize: numberValue, pageNumber: 1};
        }
    };

    /**
     * Отображение количества выбранных элементов
     */
    const showSelectedCount = () => {
        if (store.logsStore.selectedLogs.length > 0) {
            return <SelectedCount count={store.logsStore.selectedLogs.length}/>
        }
        return;
    }

    /**
     * Обработчик нажатий клавиш на Input с номером страницы
     */
    const onClickGoPage = () => {
        if (nextPage > 0 && nextPage <= Number(store.logsStore.paging.totalPages)) {
            store.logsStore.params = {...store.logsStore.params, pageNumber: nextPage};
        } else {
            dispatchToast(
                <Toast style={{minHeight: 50 + "px", minWidth: 100 + "px"}}>
                    <ToastTitle>Error!</ToastTitle>
                    <ToastBody>Invalid input. Please check!</ToastBody>
                </Toast>,
                {intent: "error"}
            );
        }
    };

    /**
     * Обработчик нажатия кнопки удалить пустые логи
     */
    const deleteEmptyHandler = async () => {
        try {
            store.loaderStore.setLoading(true);
            await services.logsService.deleteEmpty();
        } catch (e: any) {
            showErrorNotify(e);
        } finally {
            store.loaderStore.setLoading(false);
        }
    };

    /**
     * Получаем идентификаторы логов с отмеченных элементов
     */
    const getLogIdsFromSelected = (): number[] => {
        const selectedIds = Array.from(store.logsStore.selectedLogs)
            .map(id => Number(id))
            .filter(id => !isNaN(id));
        const selectedLogs = store.logsStore.logs.filter(log => selectedIds.includes(log.id));
        return selectedLogs.map(log => log.id);
    };

    /**
     * Обработчик кнопки "Скачать"
     */
    const onDownloadButton = async () => {
        try {
            store.loaderStore.setLoading(true);

            if (store.logsStore.isFilterApplied && store.logsStore.selectedLogs.length <= 0) {
                await services.fileDownloadService.requestLogByFilter(store.logsStore.params);
                showToast("Success", "This may take a while. File requested and preparing for you", "success");

            } else {
                if (store.logsStore.selectedLogs && store.logsStore.selectedLogs.length > 0) {
                    await services.fileDownloadService.requestLogsFiles(getLogIdsFromSelected());
                } else {
                    await services.fileDownloadService.requestAllLogFiles();
                }

                dispatchToast(
                    <Toast style={{minHeight: 50 + "px", minWidth: 100 + "px"}}>
                        <ToastTitle>Downloading file</ToastTitle>
                        <ToastBody>
                            <Text>This may take a while. File requested and preparing for you</Text>
                        </ToastBody>
                        <ToastFooter>
                            <Link href={DOWNLOADS_URL}>Open download page</Link>
                        </ToastFooter>
                    </Toast>,
                    {intent: "success"}
                );
            }

        } catch (error: any) {
            console.error(error);
            showErrorNotify(error);
        } finally {
            store.loaderStore.setLoading(false);
        }
    };

    return (
        <ToolsContainer>
            <div className={styles.controlContainer}>
                <LoadingButton
                    buttonText={"Download"}
                    icon=<ArrowDownload20Regular/>
                    onClick={onDownloadButton}
                    appearance={"primary"}
                />

                <LoadingButton
                    buttonText={"Delete"}
                    style={{
                        color: tokens.colorPaletteDarkOrangeForeground1
                    }}
                    onClick={() => alert("not implemented")}
                    icon={<BinRecycle20Filled/>}/>

                <LoadingButton buttonText={"Delete empty"}
                               icon=<BinRecycle20Regular/>
                               onClick={deleteEmptyHandler}
                />
                <Button appearance={"outline"}
                        onClick={() => {
                            store.logsStore.isFilterOpen = !store.logsStore.isFilterOpen
                        }}
                        icon={(store.logsStore.isFilterApplied) ? <DataFunnel20Filled/> : <DataFunnel20Regular/>}
                >
                    Filters
                </Button>
                {showSelectedCount()}
            </div>
            <div className={styles.controlPagination}>
                <Select id={selectId} onChange={onSizeChangeHandler} value={store.logsStore.paging.pageSize}>
                    <option>10</option>
                    <option>25</option>
                    <option>50</option>
                    <option>75</option>
                    <option>100</option>
                    <option>200</option>
                    <option>300</option>
                </Select>
                <Input type={"number"} min={1} placeholder={"Go to:"}
                       onChange={(_e, d) => {
                           setNextPage(Number(d.value))
                       }}
                       contentAfter={<Button icon={<ArrowRight20Regular/>}
                                             appearance={"transparent"} onClick={onClickGoPage}/>}/>
                <PaginationButtons currentPage={store.logsStore.paging.pageNumber ?? 1}
                                   totalPages={store.logsStore.paging.totalPages ?? 1}
                                   totalCount={store.logsStore.paging.totalItems ?? 1}
                                   onNextClick={onNextClickHandler}
                                   onPrevClick={onPrevClickHandler}/>
            </div>
        </ToolsContainer>
    );
}

/**
 * Имя отображаемое при отладке
 */
LogListTools.displayName = "LogListTools";

export default observer(LogListTools);