import {AxiosService} from "./axios";
import {LogPageDto} from "../models/logs/LogPageDto";
import {LogsRequestDto} from "../models/logs/LogRequestDto";
import {LogFilteringOptions} from "../models/logs/LogFilteringOptions";

/**
 * Сервис работы с логами
 */
export class LogsService {

    /**
     * Экземпляр axios сервиса
     * @private
     */
    private axios: AxiosService;

    /**
     * Конструктор
     */
    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * Получить список логов пользователя
     * @param options Опции для фильтрации. Если пусто отберёт данные по умолчанию.
     */
    async getLogsList(options?: LogsRequestDto): Promise<LogPageDto> {
        try {
            const response = await this.axios.post<LogPageDto>("/log/list",  this.convertDatesToIso(options));
            return response.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Перенести лог в корзину
     * @param logId Идентификатор лога
     */
    async moveToBin(logId: number): Promise<void> {
        try {
            await this.axios.delete(`/log/delete/${logId}`);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Удалить пустые логи
     */
    async deleteEmpty(): Promise<void> {
        try {
            await this.axios.post('/log/delete-empty');
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    async getOptions(): Promise<LogFilteringOptions> {
        try {
            const response = await this.axios.get('/log/filtering-options')
            return response.data as LogFilteringOptions;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Преобразовать объект с датами в строковый формат ISO 8601
     * @param obj Объект для преобразования
     */
     convertDatesToIso(obj: any): any {
        if (Array.isArray(obj)) {
            return obj.map((item) => this.convertDatesToIso(item)); // Применяем рекурсивно к массивам
        } else if (obj !== null && typeof obj === 'object') {
            const result: any = {};
            Object.keys(obj).forEach((key) => {
                const value = obj[key];
                if (value instanceof Date) {
                    result[key] = value.toISOString(); // Преобразуем Date в ISO
                } else if (typeof value === 'object' && value !== null) {
                    result[key] = this.convertDatesToIso(value); // Рекурсивно обрабатываем вложенные объекты
                } else {
                    result[key] = value;
                }
            });
            return result;
        }
        return obj;
    }
}