import {UserStore} from './userStore';
import {LayoutStore} from "./layoutStore";
import {LoadingStore} from "./loaderStore";
import {LogsPageStore} from "./logsPageStore";
import {BinLogsStore} from "./binLogsStore";
import {CookiesStore} from "./cookieStore";

/**
 * Корневое хранилище
 */
export class RootStore {

    /**
     * Хранилище состояния шаблона
     */
    public readonly layoutStore: LayoutStore;

    /**
     * Хранилище состояния информации о пользователе.
     */
    public readonly userStore: UserStore;

    /**
     * Хранилище загрузчика
     */
    public readonly loaderStore: LoadingStore;

    /**
     * Хранилище списка логов
     */
    public readonly logsStore: LogsPageStore;

    /**
     * Хранилище списка логов в корзине
     */
    public readonly binLogsStore: BinLogsStore;

    /**
     * Хранилище cookies
     */
    public readonly cookiesStore: CookiesStore;

    /**
     * Конструктор.
     */
    constructor() {
        this.userStore = new UserStore();
        this.layoutStore = new LayoutStore();
        this.loaderStore = new LoadingStore();
        this.logsStore = new LogsPageStore();
        this.binLogsStore = new BinLogsStore();
        this.cookiesStore = new CookiesStore();
    }
}