import {makeAutoObservable} from "mobx";
import {Paging} from "../models/common";
import {CookieItemDto, CookieRequestDto, CookiesPageDto} from "../models/cookies";

/**
 * Хранилище списка cookies
 */
export class CookiesStore {

    /**
     * Конструктор хранилища cookies
     */
    constructor() {
        makeAutoObservable(this);
    }

    /**
     * Логи
     * @private
     */
    private _cookies: CookieItemDto[] = [];

    /**
     * Опции для поиска
     * @private
     */
    private _options: string[] = [];


    /**
     * Получить список логов
     */
    public get cookies() {
        return this._cookies;
    }

    /**
     * Удалить элемент списка
     * @param cookieId Идентификатор cookies
     */
    removeItemById(cookieId: number) {
        this._cookies = this._cookies.filter(item => item.id !== cookieId);
    }

    /**
     * Сохранить список логов
     * @param value
     */
    public set cookies(value: CookieItemDto[]) {
        this._cookies = value;
    }

    /**
     * Получить список опций
     */
    public get options() {
        return this._options;
    }

    /**
     * Сохранить список опций
     * * @param value
     */
    public set options(value: string[]) {
        this._options = value;
    }

    /**
     * Параметры для запроса логов
     * @private
     */
    private _params: CookieRequestDto = {
        botId: 0,
        keyword: "",
        browserName: "",
        pageNumber: 1,
        pageSize: 50,
    }

    /**
     * Получить параметры
     */
    public get params() {
        return this._params;
    }

    /**
     * Сохранить параметры фильтрации
     * * @param value
     */
    public set params(value: CookieRequestDto) {
        this._params = value;
    }

    /**
     * Состояние пагинации на странице логов
     * @private
     */
    private _paging: Paging = {
        pageNumber: 1,
        pageSize: 50,
        totalItems: 0,
        totalPages: 1
    };

    /**
     * Получить параметры пагинации
     */
    public get paging() {
        return this._paging;
    }

    /**
     * Сохранить параметры пагинации
     * * @param value
     */
    public set paging(value: Paging) {
        this._paging = value;
    }

    /**
     * ООбновление переменных новыми данными после запроса
     * @param model
     */
    public updateData(model: CookiesPageDto) {
        this._cookies = model.cookies;
        this._paging = model.paging;
        this._params = {
            ...this._params,
            pageNumber: (model.paging.pageNumber === 0) ? 1 : model.paging.pageNumber,
            pageSize: model.paging.pageSize
        };
    }
}