import React, {useState} from "react";
import ToolsContainer from "../../containers/ToolsContainer/ToolsContainer";
import {Button, Label, Select, tokens} from "@fluentui/react-components";
import {ArrowClockwiseRegular, DeleteRegular} from "@fluentui/react-icons";
import PaginationButtons from "../../components/PaginationButtons/PaginationButtons";
import {useServices, useStore} from "../../hooks";
import ActionDialog from "../../components/ActionDialog";
import {LoadingButton} from "../../components/ButtonWithLoadingState";
import {observer} from "mobx-react-lite";

/**
 * Инструменты работы с Корзиной
 * @constructor
 */
const BinTools: React.FC = () => {
    const store = useStore();
    const services = useServices();
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    /**
     * Обработчик изменения количества элементов на странице
     */
    const onSizeChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newSize = Number(event.target.value);

        // Обновляем размер страницы в сторе
        store.binLogsStore.paging.pageSize = newSize;

        // Запрашиваем данные с новым размером страницы
        try {
            services.binService.getDeleteList({
                pageNumber: 1,  // Обновляем данные с первой страницы
                pageSize: newSize,
            });
        } catch (error: any) {
            console.error(error);
        }
    };

    /**
     * Обработчик для переключения на следующую страницу
     */
    const onNextPageHandler = () => {
        const currentPage = store.binLogsStore.paging.pageNumber;
        const totalPages = store.binLogsStore.paging.totalPages;

        if (currentPage < totalPages) {
            const nextPage = currentPage + 1;
            store.binLogsStore.paging.pageNumber = nextPage;

            // Запрашиваем данные для следующей страницы
            try {
                services.binService.getDeleteList({
                    pageNumber: nextPage,
                    pageSize: store.binLogsStore.paging.pageSize,
                });
            } catch (error: any) {
                console.error(error);
            }
        }
    };

    /**
     * Обработчик для переключения на предыдущую страницу
     */
    const onPrevPageHandler = () => {
        const currentPage = store.binLogsStore.paging.pageNumber;

        if (currentPage > 1) {
            const prevPage = currentPage - 1;
            store.binLogsStore.paging.pageNumber = prevPage;

            // Запрашиваем данные для предыдущей страницы
            try {
                services.binService.getDeleteList({
                    pageNumber: prevPage,
                    pageSize: store.binLogsStore.paging.pageSize,
                });
            } catch (error: any) {
                console.error(error);
            }
        }
    };

    /**
     * Восстановить все логи
     */
    const recoverAll = async () => {
        try {
            await services.binService.recoverAll();
            store.binLogsStore.logs = [];
        } catch (error: any) {
            console.error(error);
        }
    };

    /**
     * Удалить все логи
     */
    const deleteAll = async () => {
        try {
            await services.binService.deleteAll();
            store.binLogsStore.logs = [];
            store.binLogsStore.paging.pageNumber = 1;
            store.binLogsStore.paging.totalItems = 0;
        } catch (error: any) {
            console.error(error);
        } finally {
            setIsDialogOpen(false);  // Закрыть диалог после удаления
        }
    };

    return (
        <ToolsContainer>
            {/* Левая сторона: кнопки "Recovery All" и "Delete All" */}
            <div style={{display: "flex", alignItems: "center", gap: "1em"}}>
                <LoadingButton appearance={"primary"}
                               icon={<ArrowClockwiseRegular/>}
                               onClick={recoverAll}
                               buttonText={"Recover All"}/>

                <ActionDialog
                    title={"Warning: This action cannot be undone!"}
                    isOpen={isDialogOpen}
                    actions={
                        <>
                            <Button appearance="secondary" onClick={() => setIsDialogOpen(false)}>
                                Cancel
                            </Button>
                            <Button appearance="primary"
                                    style={{background: tokens.colorPaletteDarkOrangeForeground1}}
                                    onClick={deleteAll}>
                                Delete All
                            </Button>
                        </>}
                    trigger={
                        <Button
                            appearance={"primary"}
                            icon={<DeleteRegular/>}
                            style={{background: tokens.colorPaletteDarkOrangeForeground1}}
                            onClick={() => setIsDialogOpen(true)}>  {/* Открыть диалог при клике */}
                            Delete All
                        </Button>}
                    content={"Are you sure you want to delete all the items? This action cannot be undone."}
                />
            </div>

            {/* Правая сторона: выбор количества на странице и пагинация */}
            <div style={{display: "flex", alignItems: "center", gap: "1em"}}>
                <div style={{display: "flex", alignItems: "center"}}>
                    <Label style={{marginRight: "0.5em"}}>Items per page:</Label>
                    <Select onChange={onSizeChangeHandler} value={store.binLogsStore.paging.pageSize.toString()}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={200}>200</option>
                        <option value={300}>300</option>
                    </Select>
                </div>
                <PaginationButtons
                    currentPage={store.binLogsStore.paging.pageNumber}
                    onNextClick={onNextPageHandler}  // Обработчик для следующей страницы
                    onPrevClick={onPrevPageHandler}  // Обработчик для предыдущей страницы
                    totalCount={store.binLogsStore.paging.totalItems}
                    totalPages={store.binLogsStore.paging.totalPages}
                />
            </div>
        </ToolsContainer>
    );
};

/**
 * Имя отображаемое при отладке
 */
BinTools.displayName = "BinTools";

export default observer(BinTools);
